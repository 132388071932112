<template>
  <div class="container">
    <div class="title-box">
      <img src="/imgs/logo.png" alt="">
      <a href="http://www.uni02.com/" target="_blank"> Uni系列无界云仓 > </a>
    </div>
    <div class="tab-box">
      <div class="tab-list">
        <div class="tab-item" @click="goPage('/')">首页</div>
        <div class="tab-item" :class="{'avtive':avtive==='aboutus'}" @click="goPage('/aboutus')">关于我们</div>
        <div class="tab-item" :class="{'avtive':avtive==='joinus'}" @click="goPage('/joinus')">招贤纳士</div>
        <div class="tab-item" :class="{'avtive':avtive==='contactus'}" @click="goPage('/contactus')">联系我们</div>
        <div class="tab-item" :class="{'avtive':avtive==='privacy'}" @click="goPage('/privacy')">法律信息</div>
      </div>
    </div>
    <div class="page-content">
      <router-view />
    </div>
    <div class="bottom-box">
      <img
        src="/imgs/home/container5.jpg"
        alt=""
        class="bottom-box-bg"
      >
      <div class="bottom-box-box">
        <div class="left-box">
          <img src="/imgs/home/logo.png" alt="">
          <div class="bottom-box-left-text">
            CCTV《匠心》栏目中华民族100家创新型高新技术企业
          </div>
          <div class="codeList">
            <div class="codeItem">
              <img
                src="/imgs/home/codeItem1.png"
                alt=""
              >
              <div class="code-text">无界SaaS公众号</div>
            </div>
            <div class="codeItem">
              <img
                src="/imgs/home/codeItem2.jpg"
                alt=""
              >
              <div class="code-text">Uni无界云仓公众号</div>
            </div>
            <div class="codeItem">
              <img
                src="/imgs/home/codeItem3.jpg"
                alt=""
              >
              <div class="code-text">无界SaaS安卓端</div>
            </div>
            <div class="codeItem">
              <img
                src="/imgs/home/codeItem4.png"
                alt=""
              >
              <div class="code-text">无界SaaS苹果端</div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="right-top-box">
            <div class="about-list" @click="goPage('/aboutus')">
              <div class="about-title">关于我们</div>
              <div class="about-item">企业简介</div>
              <div class="about-item">企业文化</div>
              <div class="about-item">创新优势</div>
              <div class="about-item">资质证书</div>
            </div>
            <div class="about-list" @click="goPage('/joinus')">
              <div class="about-title">招贤纳士</div>
              <div class="about-item">社会招聘</div>
              <div class="about-item">校园招聘</div>
            </div>
            <div class="about-list" @click="goPage('/contactus')">
              <div class="about-title">联系我们</div>
              <div class="about-item">客服热线</div>
              <div class="about-item">合作洽谈</div>
            </div>
            <div class="about-list" @click="goPage('/privacy')">
              <div class="about-title">法律信息</div>
              <div class="about-item">隐私政策</div>
            </div>
          </div>
          <div class="recordNo">
            Copyright ©2014-2021 UNI02.COM All Rights Reserved
            <a
              href="https://beian.miit.gov.cn/?spm=a2cmq.17629970.J_9220772140.110.f0d079fewWqZNB#/Integrated/index"
              target="_blank"
            >粤ICP备2023005663号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      avtive: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val.name)
        this.avtive = val.name
      },
      immediate: true
    }
  },
  methods: {
    goPage(path, key) {
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  overflow: hidden;
}
.title-box {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.title-box a {
  color: #002fa7;
  font-weight: bold;
  text-decoration: none;
}
.tab-box {
  background-color: #002fa7;
  color: #fff;
  padding: 0 20%;
  text-align: center;
  .tab-list {
    display: flex;
    .tab-item {
      flex: 1;
      padding: 0.1rem 0;
      cursor: pointer;
    }
    .avtive {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 40%;
        right: 40%;
        bottom: 0;
        height: 4px;
        background-color: #fff;
      }
    }
  }
}
.page-content {
  padding: 0 15%;
  text-align: center;
  position: relative;
}
.bottom-box {
  position: relative;
  color: #fff;
  text-align: left;
}

.bottom-box-bg {
  display: block;
  width: 100%;
}

.bottom-box-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 3% 10%;
  .left-box {
    flex: 1;
  }
  .bottom-box-left-text {
    margin: 20px 0;
  }
  .codeList {
    padding-right: 18%;
  }
  .right-box {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .left-box .codeList {
    display: flex;
  }
  .codeItem {
    flex: 1;
    text-align: center;
    font-size: 13px;
  }

  .right-top-box {
    display: flex;
    flex: 1;
  }

  .right-top-box .about-list {
    flex: 1;
    cursor: pointer;
  }

  .right-top-box .about-list .about-title {
    font-size: 18px;
  }

  .right-top-box .about-list .about-item {
    margin-top: 0.1rem;
    color: #b8ccff;
  }

  .recordNo {
    color: #b8ccff;
    text-align: center;
  }

  .recordNo a {
    color: #b8ccff;
    text-decoration: none;
  }
}
</style>
